<template>
  <div class="develop-container">
    <h2>内部工具:</h2>
    <ul>
      <li>
        <a-link @click="this.jumpWebPage('/develop/exchange-code',true)">生成兑换码</a-link>
      </li>
      <li>
        <a-link @click="this.jumpWebPage('/develop/paas-device-config',true)">发送设备Config</a-link>
      </li>
      <li>
        <a-link @click="this.jumpWebPage('/develop/paas-device-vip',true)">设置设备VIP</a-link>
      </li>
      <li>
        <a-link @click="this.jumpWebPage('/develop/send-imgs',true)">发送图片</a-link>
      </li>
      <li>
        <a-link @click="this.jumpWebPage('/develop/wifi-power',true)">设置WIFI功率</a-link>
      </li>
      <li>
        <a-link @click="this.jumpWebPage('/develop/question-back',true)">查询问题反馈视频</a-link>
      </li>
    </ul>
    <hr />
    <label>process:</label>
    <pre>{{ JSON.stringify(process, null, 4) }}</pre>
    <el-divider></el-divider>
    <label>env:</label>
    <pre>{{ JSON.stringify(env, null, 4) }}</pre>
    <el-divider></el-divider>
    <label>env:</label>
    <pre>{{ JSON.stringify(argv, null, 4) }}</pre>
    <el-divider></el-divider>
    <label>env:</label>
    <pre>{{ JSON.stringify(features, null, 4) }}</pre>
    <el-divider></el-divider>
    <label>position:</label>
    <pre>{{ JSON.stringify(position, null, 4) }}</pre>
    <el-divider></el-divider>
    <label>queryNode:</label>
    <!--    <div>-->
    <!--      <input type="radio" v-for="item in serviceNameOptions" :value="item" v-model="serviceName"/>{{ item }}-->
    <!--    </div>-->
    <!--    <div>-->
    <!--      <input type="checkbox" v-for="item in deployLevelOptions" :value="item.name"-->
    <!--             v-model="item.checked"/>{{ item.name }}-->
    <!--    </div>-->
    <!--    <input type="text" v-model="serviceName"></input>-->
    <!--    <input type="text" v-model="serviceName"></input>-->
    <pre>{{ JSON.stringify(nodes, null, 4) }}</pre>
  </div>
</template>

<script>
import request from "@/utils/request";
import SockJS from "sockjs-client";

export default {
  data() {
    return {
      process: process,
      env: process.env,
      argv: process.argv,
      features: process.features,
      position: null,

      serviceNameOptions: ["console", "paas", "saas", "auth"],
      deployLevelOptions: [{ name: "staging", checked: true }, { name: "prod", checked: false }],

      serviceName: "console",
      deployLevels: ["staging", "prod"],
      nodes: []
    };
  },
  created() {
    // this.getPosition().then(position => this.position = position)
    this.queryNode().then(nodes => {
      this.nodes = nodes;
      // this.createSockjs(nodes[1])
    });
  },
  methods: {
    createSockjs(node) {
      /*
      {
        "nodeName": "staging-cn",
        "autoSelect": true,
        "baseUrl": "https://staging-cn-a4x-console.addx.live",
        "serviceId": 701,
        "serviceName": "console",
        "status": 1
      }
     */
      const url = node.baseUrl + "/aiDemo/sockjs";
      const sock = new SockJS(url);
      sock.onopen = () => {
        console.log("sock open!", url);
      };
      sock.onmessage = (e) => {
        const result = JSON.parse(e.data);
        console.log("sock message!", result);
      };
      sock.onclose = () => {
        console.log("sock close!");
      };
    },
    getPosition() {
      return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
          reject(new Error("浏览器不支持定位"));
        } else {
          navigator.geolocation.getCurrentPosition((position) => {
            console.log("getCurrentPosition success:", position);
            resolve(position);
          }, (error) => {
            console.log("getCurrentPosition error:", error);
            switch (error.code) {
              case error.PERMISSION_DENIED:
                reject(new Error("User denied the request for Geolocation."));
                return;
              case error.POSITION_UNAVAILABLE:
                reject(new Error("Location information is unavailable."));
                return;
              case error.TIMEOUT:
                reject(new Error("The request to get user location timed out."));
                return;
            }
            reject(new Error("An unknown error occurred."));
          });
        }
      });
    },
    async queryNode() {
      const requestConfig = {
        url: "/cloud/queryNode",
        method: "post",
        data: { serviceName: this.serviceName, deployLevels: this.deployLevels }
      };
      const response = await request(requestConfig);
      console.log("queryNode response:", response);
      if (!response || response.code !== 0) {
        throw new Error("查询节点失败!");
      }
      return response.data;
    }
  },
  watch: {
    serviceName(newVal, oldVal) {
      console.log("serviceName:", oldVal, "->", newVal);
    },
    deployLevels(newVal, oldVal) {
      console.log("deployLevels:", oldVal, "->", newVal);
    }
  }
};
</script>
